.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        @apply ring-0;
    }
}

.bg-red-800{
    background-color: #9b2c2c;
}

.bg-red-700{
    background-color: #c53030;
}